import { handleOverTheAirUpdate } from "@repo/shared/service-worker";
import { getSerwist } from "@serwist/vite/browser";
import { Serwist } from "@serwist/window";
import { ServiceWorkerMessage } from "./types";

let isReloading = false;
const reload = () => {
  if (isReloading) return;
  isReloading = true;
  window.location.reload();
};

let isInitialized = false;
export const initializeServiceWorkerHandlers = async () => {
  if (!import.meta.env.PROD) return;

  let serwist = await getSerwist();

  if (window.trustedTypes?.createPolicy) {
    const ttPolicy = window.trustedTypes.createPolicy("ttPolicy", {
      createScriptURL: (string) => string,
    });

    serwist = new Serwist(ttPolicy.createScriptURL("/service-worker.js"));
  }

  const isSerwistAvailable = "serviceWorker" in navigator && serwist;
  if (!isSerwistAvailable) {
    console.log(
      "[service-worker-client] serwist not available, skip initializing service worker handlers",
    );
    return;
  }

  if (isInitialized) return;
  isInitialized = true;

  navigator.serviceWorker.addEventListener("controllerchange", reload);

  navigator.serviceWorker.addEventListener("message", (event) => {
    const data = event.data as ServiceWorkerMessage;

    if (data.action === "reload") {
      reload();
    }
  });

  /**
   *  Manually register service worker
   *  @link https://serwist.pages.dev/docs/next/configuring/register
   */
  await serwist?.register();

  handleOverTheAirUpdate();
};
